import {Video} from './video';
import {TitleImage} from './title-image';
import {Episode} from './episode';
import {Season} from './season';
import {Review} from './review';
import {Genre} from '@app/titles/models/genre';
import {Keyword} from '@app/titles/models/keyword';
import {ProductionCountry} from '@app/titles/models/production-country';
import {Person} from '@app/titles/models/person';

export interface EpisodeCredit extends Episode {
  pivot: TitleCreditPivot;
}

export interface TitleDownloadLink {
  category: string;
  created_at: Date;
  direct: number;
  encoder: string;
  format: string;
  id: number;
  language: string;
  link: string;
  name: string;
  quality: string;
  runtime: 144;
  size: string;
  status: number;
  sub_link: null;
  title_id: number;
  updated_at: Date;
  user_id: null;
}

export interface TitleCredit extends Person {
  pivot: TitleCreditPivot;
}

export interface PersonCredit extends Title {
  credited_episode_count?: number;
  episodes: EpisodeCredit[];
  pivot: TitleCreditPivot;
}

export interface TitleCreditPivot {
  id: number;
  job: string;
  department: 'directing' | 'writing' | 'actors' | 'creators' | 'Writing' | 'Directing';
  character: string;
  order:number
}

export const TITLE_MODEL = 'title';
export const MOVIE_MODEL = 'movie';
export const SERIES_MODEL = 'series';

export interface Title {
  id: number;
  name: string;
  original_title: string;
  model_type: typeof TITLE_MODEL;
  is_series: boolean;
  status: 'released' | 'upcoming' | 'ongoing' | 'ended';
  description: string;
  tagline: string;
  runtime: number;
  rating: number;
  budget: number;
  poster?: string;
  backdrop: string;
  revenue: number;
  views: number;
  popularity: number;
  seasons_count: number;
  release_date: string;
  year: number;
  genres: Genre[];
  keywords: Keyword[];
  production_countries: ProductionCountry[];
  videos: Video[];
  all_videos?: Video[];
  primary_video: Video;
  primary_video_count?: number;
  certification?: string;
  images: TitleImage[];
  season?: Season;
  seasons?: Season[];
  reviews?: Review[];
  language: string;
  updated_at?: string;
  download_links: TitleDownloadLink[];
  trailer_src: string[];
  full_video_src: string[];
  tmdb_vote_average: string;
  // fakeUrl?: string
  url_id?: number;
  imdb_id?: string;
  tmdb_id?: number;
  persian_description?: string;
  persian_name?: string;
  persian_tagline?: string;
}
