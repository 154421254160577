import React from 'react';
import {Dialog} from '@common/ui/overlays/dialog/dialog';
import {DialogHeader} from '@common/ui/overlays/dialog/dialog-header';
import {DialogBody} from '@common/ui/overlays/dialog/dialog-body';
import {Trans} from '@common/i18n/trans';
import {Link} from 'react-router-dom';
import {closeDialog} from '@common/ui/overlays/store/dialog-store';

function BanDialog() {
  const expireAt = localStorage.getItem('expire_ban');

  return (
    <Dialog>
      <DialogHeader hideDismissButton>
        <Trans message="Your Account has been suspended" />
      </DialogHeader>
      <DialogBody className="w-full">
        <p>
          {/* {expireAt && expireAt !== 'null' ? (
            <Trans message="Admin has suspended your account permanently" />
          ) : (
            <React.Fragment>
              <Trans message="Admin has suspended your account until" />
              <Trans message={expireAt as string} />
            </React.Fragment>
          )} */}
          <Trans message="Admin has suspended your account" />
        </p>
        <div className="w-400">
          <p>
            <Trans message="If you think that you should not have been suspended, contact us through the link below." />
          </p>
          <Link
            to={'/contact'}
            className="text-base"
            onClick={() => closeDialog(BanDialog)}
          >
            <Trans message="Contact Link" />
          </Link>
        </div>
      </DialogBody>
    </Dialog>
  );
}

export default BanDialog;
