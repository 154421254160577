import {Trans} from '../i18n/trans';
import {Navbar} from '../ui/navigation/navbar/navbar';
import {Form} from '../ui/forms/form';
import {useForm} from 'react-hook-form';
import {
  ContactPagePayload,
  useSubmitContactForm,
} from './use-submit-contact-form';
import {FormTextField} from '../ui/forms/input-field/text-field/text-field';
import {Button} from '../ui/buttons/button';
import {useRecaptcha} from '../recaptcha/use-recaptcha';
import {StaticPageTitle} from '../seo/static-page-title';
import {Footer} from '@common/ui/footer/footer';
import {Col, Container, Row} from 'react-bootstrap';
import {useThemeSelector} from '@common/ui/themes/theme-selector-context';
import clsx from 'clsx';
import {useUserProfile} from '@app/profile/requests/use-user-profile';
import {useAuth} from '@common/auth/use-auth';

export function ContactUsPage() {
  const {user, isLoggedIn} = useAuth();

  const form = useForm<ContactPagePayload>({
    defaultValues: {
      email: user?.email,
    },
  });
  const submitForm = useSubmitContactForm(form);
  const {verify, isVerifying} = useRecaptcha('contact');
  const {selectedTheme} = useThemeSelector();

  return (
    <>
      <div className="flex min-h-screen flex-col bg-alt">
        <Navbar
          className="sticky top-0 flex-shrink-0"
          menuPosition="contact-us-page"
        />
        <div
          className="iq-breadcrumb"
          style={{
            backgroundImage: `url(https://t4.ftcdn.net/jpg/05/08/77/97/360_F_508779720_mevGw0UiCDurA6A195ayIk5sxaGFwuEu.jpg)`,
          }}
        >
          <Container fluid>
            <Row className="align-items-center">
              <Col sm="12">
                <nav className="text-center">
                  <h2 className={`title capitalize`}>
                    <Trans message="Contact us" />
                  </h2>
                </nav>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className={clsx(
            'container mx-auto flex flex-auto items-center justify-center p-24 md:p-40',
            selectedTheme.is_dark ? 'bg-black' : 'bg-white',
          )}
        >
          <div className="max-w-620 rounded border bg-paper p-24">
            {' '}
            <h1
              className={`text-2xl ${selectedTheme.is_dark ? 'text-white' : 'text-black'}`}
            >
              <Trans message="Contact us" />
            </h1>
            <p className="mb-30 mt-4 text-sm">
              <Trans message="Please use the form below to send us a message and we'll get back to you as soon as possible." />
            </p>
            <Form
              form={form}
              onSubmit={async value => {
                const isValid = await verify();
                if (isValid) {
                  submitForm.mutate(value);
                }
              }}
            >
              <FormTextField
                label={<Trans message="Name" />}
                name="name"
                required
                className={clsx('mb-20')}
              />
              <FormTextField
                label={<Trans message="Email" />}
                name="email"
                required
                type="email"
                className="mb-20"
                inputClassName="!text-left disabled:opacity-50"
                disabled
              />
              <FormTextField
                label={<Trans message="Message" />}
                name="message"
                required
                inputElementType="textarea"
                className="mb-20 !resize-none"
                rows={8}
              />
              <Button
                className="disabled:!border-none disabled:!bg-toast"
                type="submit"
                variant="flat"
                color="primary"
                disabled={submitForm.isPending || isVerifying || !isLoggedIn}
              >
                <Trans
                  message={!isLoggedIn ? 'You Need To Login First' : 'Send'}
                />
              </Button>
            </Form>
          </div>
        </div>
        <Footer className="container mx-auto flex-shrink-0 px-24" />
      </div>
    </>
  );
}

// </>
//   <div className="flex flex-col bg-alt min-h-screen">
//     <StaticPageTitle>
//       <Trans message="Contact us" />
//     </StaticPageTitle>
//     <Navbar
//       className="flex-shrink-0 sticky top-0"
//       menuPosition="contact-us-page"
//     />
//     <div className="container p-24 md:p-40 mx-auto flex-auto flex items-center justify-center">
//       <div className="border rounded bg-paper p-24 max-w-620">
//         <h1 className="text-2xl">
//           <Trans message="Contact us" />
//         </h1>
//         <p className="text-sm mt-4 mb-30">
//           <Trans message="Please use the form below to send us a message and we'll get back to you as soon as possible." />
//         </p>
//         <Form
//           form={form}
//           onSubmit={async value => {
//             const isValid = await verify();
//             if (isValid) {
//               submitForm.mutate(value);
//             }
//           }}
//         >
//           <FormTextField
//             label={<Trans message="Name" />}
//             name="name"
//             required
//             className="mb-20"
//           />
//           <FormTextField
//             label={<Trans message="Email" />}
//             name="email"
//             required
//             type="email"
//             className="mb-20"
//           />
//           <FormTextField
//             label={<Trans message="Message" />}
//             name="message"
//             required
//             inputElementType="textarea"
//             className="mb-20"
//             rows={8}
//           />
//           <Button
//             type="submit"
//             variant="flat"
//             color="primary"
//             disabled={submitForm.isPending || isVerifying}
//           >
//             <Trans message="Send" />
//           </Button>
//         </Form>
//       </div>
//     </div>
//     <Footer className="container mx-auto px-24 flex-shrink-0" />
//   </div>
