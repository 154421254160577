import { apiClient, queryClient } from "@common/http/query-client";
import { useMutation } from "@tanstack/react-query";

export const useMarkAsRead = () => {
    return useMutation({
        mutationKey: ["mark_as_read"],
        mutationFn: (id:number) => markNotificationsAsRead(id),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ["myNotifications", "notifications"]
            })
        }
    })
}

function markNotificationsAsRead(id: number): Promise<Response> {
    return apiClient
      .post(`notifications/${id}/mark-as-read`)
      .then(r => r.data);
  }
  