import React, {Children, Fragment, ReactNode} from 'react';
import clsx from 'clsx';

interface BulletSeparatedItemsProps {
  children: ReactNode;
  className?: string;
  isInTitle?: boolean
}

export function BulletSeparatedItems({
  children,
  className,
  isInTitle
}: BulletSeparatedItemsProps) {
  const items = Children.toArray(children);
  return (
    <div
      className={clsx(
        'flex flex-wrap items-center gap-4 overflow-hidden',
        className,
      )}
    >
      {items.map((child, index) => (
        <div className="flex items-center gap-1" key={index}>
          {!isInTitle && <div>|</div>}
          <div className="whitespace-nowrap">{child}</div>
        </div>
      ))}
    </div>
  );
}
